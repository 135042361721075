import { TARGET } from "./target";
import { CATRINALLA } from "./catrinalla";
import { DICTIONARY } from "./dictionary";
import { arrayBuffer } from "stream/consumers";

//let catrinalla = "false";

function getTargetWords() {
  return toUpperCaseAndNormalize(TARGET);
}

function getCatrinallaWords() {
  return toUpperCaseAndNormalize(CATRINALLA);
}

function getDictionaryWords() {
  return toUpperCaseAndNormalize(DICTIONARY);
}

function toUpperCaseAndNormalize(array:string[]){
  let arrayNormlized: string[] = [];
  //let words = WORDS //TODO BORRAR
  array.forEach(it => 
                  arrayNormlized.push(
                  it.toUpperCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  )
                )
  return arrayNormlized;
}

export function getWordOfTheDay() {
  debugger;
  let catrinalla = localStorage.getItem("catri");
  let wordOfTheDay = "";
  if(catrinalla==="true"){
    const catrin = getCatrinallaWords();
    wordOfTheDay = catrin[getDayOfTheYear()]    
  }else{
    const words = getTargetWords();
    wordOfTheDay = words[getDayOfTheYear()];
  }
  return wordOfTheDay;
}

export function isValidWord(word: string) {
  const words = getDictionaryWords(); 
  if(word.includes("Ñ")){
    return true;
  }
  return words.includes(word.toUpperCase());
}

function getDayOfTheYear() {
/*
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff =
    <any>now -
    <any>start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
   */
  let max = 0;
  let catrinalla = localStorage.getItem("catri");
  if(catrinalla==="true"){
    max = CATRINALLA.length;
  }else{
    max = TARGET.length;
  }
  
  let min = 0;
  let randomNumber = Math.floor(Math.random() * (max - min) + min);
  return randomNumber;
}
