import styles from "./modal.module.scss";
import React from "react"

interface Props {
  onClick: (e: any) => void;
}

let btnClass = [
  'btn',
  'btn-primary',
  'btn-info'
]

const Instructions: React.FC<Props> = ({ onClick }) => (
  <>
  <div className={styles.modalViewContainer}>
    <div className={styles.modalContainer} >
      <h2>Cómo chugar?</h2>
      <div className={styles.paragraph}>
        <p>Preba de debinar a PAROLA prebando-ne 6 begadas.</p>
        <p>Cada begada que prebes ha d'estar una parola balida de 5 letras. Preta o botón "PREBAR" ta chugar.</p>
        <p>Dimpués de cada intento, a color d'as zillas cambiará ta amostrar en qué mida ye estato ro tuyo intento amán d'enzertar a parola amagata.</p>
        <p>Ta simplificar o chuego as parolas no tienen tildes e s'amostran siempre en mayusclas.</p>
        <p>Si pretas o botón "catrinalla" a parola a debinar será dentro d'una lista de 300 parolas d'emplego cutiano en cuenta d'entre más de 2.500 parolas d'aragonés.</p>
        <p>Iste chuego fa serbir as <a href="https://www.consello.org/pdf/normasgraficas.pdf" target="_blank">normas graficas de l'aragonés</a>, o modelo grafico istorico que naxe fa 50 añadas d'o consenso entre fablans de aragonés en a decada de 1980.</p>
        <p>Exemplos:</p>
        <img src="./wordle_verde.png" />
        <p>A letra D ye en a parola y en o puesto correuto.</p>
        <img src="./wordle_amarillo.png" />
        <p>A letra A ye en a parola pero en o puesto equibocau.</p>
        <img src="./wordle_gris.png" />
        <p>Denguna letra ye en a parola en garra puesto.</p>       
      </div>
      <div className={styles.centerButton} >
      <button onClick={onClick} className={styles.closeButton}>Trancar</button>
      </div>
    </div>
  </div>     
  </>
);

export default Instructions;




