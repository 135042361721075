export const CATRINALLA = [ 
    "abaxo",
    "abete",
    "adoba",
    "aduya",
    "afear",
    "Agnés",
    "agora",
    "Ainés",
    "airaz",
    "alica",
    "alzar",
    "alzau",
    "ambre",
    "ampra",
    "añada",
    "ancón",
    "aneda",
    "aneto",
    "anque",
    "ansar",
    "ansir",
    "antis",
    "arnal",
    "asina",
    "ature",
    "augua",
    "ayere",
    "badil",
    "baixo",
    "balde",
    "baxar",
    "bazía",
    "beire",
    "belún",
    "biera",
    "bigot",
    "birla",
    "birol",
    "bocau",
    "bogal",
    "boira",
    "brete",
    "brilo",
    "bruxa",
    "buega",
    "bueña",
    "bufar",
    "buixo",
    "butir",
    "caixa",
    "caixo",
    "camín",
    "canet",
    "canta",
    "capar",
    "caper",
    "capir",
    "catén",
    "caxal",
    "caxón",
    "cayer",
    "chaca",
    "chelo",
    "chera",
    "chesa",
    "cheso",
    "chilo",
    "chiro",
    "chito",
    "chiva",
    "choca",
    "Chuan",
    "chuco",
    "chugo",
    "Chusé",
    "chuzo",
    "cobar",
    "colla",
    "coxín",
    "craba",
    "crapa",
    "creba",
    "cuasi",
    "cucar",
    "cursa",
    "dalla",
    "debán",
    "dende",
    "dexar",
    "dillá",
    "diner",
    "dixar",
    "dizir",
    "dople",
    "dozén",
    "Dulza",
    "enuyo",
    "escar",
    "esfer",
    "estiu",
    "estol",
    "fabar",
    "fabas",
    "fabla",
    "fabón",
    "faina",
    "faixa",
    "faixo",
    "falca",
    "falsa",
    "farto",
    "farta",
    "fayar",
    "feble",
    "fecal",
    "feche",
    "femar",
    "fendo",
    "fiemo",
    "figau",
    "filar",
    "filet",
    "fitar",
    "fixar",
    "fizar",
    "flama",
    "fogar",
    "fongo",
    "forau",
    "forca",
    "forno",
    "frexe",
    "fuesa",
    "fueva",
    "fuina",
    "fuñir",
    "fusta",
    "fuyir",
    "gafar",
    "garmo",
    "glera",
    "gosar",
    "ideya",
    "ixada",
    "ixata",
    "ixena",
    "jadón",
    "jibar",
    "jobar",
    "jopar",
    "joreo",
    "lamín",
    "lexía",
    "lexos",
    "leyal",
    "leyer",
    "lezna",
    "libel",
    "linia",
    "lueca",
    "luita",
    "lurte",
    "lusco",
    "mallo",
    "malta",
    "maxia",
    "medro",
    "melsa",
    "menar",
    "merar",
    "metat",
    "meyán",
    "meyar",
    "miaja",
    "micas",
    "midir",
    "Milia",
    "Milio",
    "mocar",
    "mofla",
    "mosen",
    "mueso",
    "mugar",
    "museu",
    "muxol",
    "muyir",
    "Nadal",
    "naxer",
    "Nazia",
    "Nazio",
    "nomás",
    "nople",
    "ordio",
    "ortal",
    "pansa",
    "paret",
    "payes",
    "peazo",
    "pebre",
    "peito",
    "peixe",
    "penca",
    "perén",
    "petar",
    "petén",
    "peyor",
    "piazo",
    "picha",
    "pifar",
    "pinet",
    "piñol",
    "pitos",
    "pixal",
    "pixar",
    "pixín",
    "pizca",
    "pizia",
    "plega",
    "plego",
    "pocha",
    "porro",
    "posar",
    "preba",
    "pueyo",
    "puñau",
    "punda",
    "punir",
    "purna",
    "puyal",
    "puyar",
    "puyón",
    "quera",
    "quexa",
    "rader",
    "radiz",
    "Rafel",
    "ratet",
    "rayón",
    "rebel",
    "rebol",
    "recau",
    "redir",
    "redol",
    "refer",
    "Remón",
    "repui",
    "robín",
    "roina",
    "rolde",
    "roñar",
    "roñiu",
    "rotiu",
    "rozín",
    "rudio",
    "rutir",
    "rutiu",
    "ruxar",
    "ruxón",
    "sacre",
    "saper",
    "sapia",
    "sarda",
    "sarga",
    "sarra",
    "selva",
    "simén",
    "siñal",
    "sirga",
    "sisal",
    "sisón",
    "soniu",
    "sucre",
    "tañer",
    "tañiu",
    "tardi",
    "texer",
    "tixer",
    "tixir",
    "tocho",
    "tollo",
    "tormo",
    "torna",
    "tozal",
    "traxe",
    "trena",
    "Tresa",
    "truca",
    "truen",
    "turma",
    "turmo",
    "tusir",
    "ubrir",
    "uebra",
    "ueito",
    "uella",
    "uello",
    "uesca",
    "ungla",
    "Úrbez",
    "vedau",
    "veyer",
    "Vítor",
    "Vizén",
    "zinca",
    "zucre",
    "zueca",
]