import styles from "./keyboard.module.scss";
import classNames from "classnames/bind";

interface KeyboardProps {
  keys: string[];
  onInput: (letter: string) => void;
  onEnter: () => void;
  onDelete: () => void;
}

const classes = classNames.bind(styles);

export default function Keyboard({
  keys,
  onInput,
  onEnter,
  onDelete,
}: KeyboardProps) {
  function getStyle(letter: string, pos: number) {
    const keyStyles = classes(styles.key);

    return keyStyles;
  }

  function handleInput(e: any) {
    onInput(e.target.textContent);
  }

  function handleEnter(e: any) {
    onEnter();
  }
  function handleDelete(e: any) {
    onDelete();
  }

  return (
    <div className={styles.keyboardContainer}>
      <div className={styles.keyboardRow}>
      {Array.from(Array(10)).map((_, i) => (
        <button key={i} className={getStyle(keys[i], i)} onClick={handleInput}>
          {keys[i]}
        </button>
      ))}
      </div>
    {/* <div className={styles.emptyKey}></div>*/}
    <div className={styles.keyboardRow}>
      {Array.from(Array(10)).map((_, i) => (
        <button
          key={i + 10}
          className={getStyle(keys[i], i)}
          onClick={handleInput}
        >
          {keys[i + 10]}
        </button>
      ))}
      </div>
      <div className={styles.keyboardRow}>
      <button className={styles.enterKey} onClick={handleEnter}>
        PREBAR
      </button>
      {Array.from(Array(7)).map((_, i) => (
        <button
          key={i + 20}
          className={getStyle(keys[i], i)}
          onClick={handleInput}
        >
          {keys[i + 20]}
        </button>
      ))}
      <button className={styles.deleteKey} onClick={handleDelete}>
        ESBORRAR
      </button>
      </div>
    </div>
  );
}
